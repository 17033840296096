/* Segoe UI */

@font-face {
    font-family: 'Segoe UI';
    src: url('./Segeo/SegoeUI-BoldItalic.eot');
    src: url('./Segeo/SegoeUI-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./Segeo/SegoeUI-BoldItalic.woff2') format('woff2'),
        url('./Segeo/SegoeUI-BoldItalic.woff') format('woff'),
        url('./Segeo/SegoeUI-BoldItalic.ttf') format('truetype'),
        url('./Segeo/SegoeUI-BoldItalic.svg#SegoeUI-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('./Segeo/SegoeUI-Bold.eot');
    src: url('./Segeo/SegoeUI-Bold.eot?#iefix') format('embedded-opentype'),
        url('./Segeo/SegoeUI-Bold.woff2') format('woff2'),
        url('./Segeo/SegoeUI-Bold.woff') format('woff'),
        url('./Segeo/SegoeUI-Bold.ttf') format('truetype'),
        url('./Segeo/SegoeUI-Bold.svg#SegoeUI-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('./Segeo/SegoeUI.eot');
    src: url('./Segeo/SegoeUI.eot?#iefix') format('embedded-opentype'),
        url('./Segeo/SegoeUI.woff2') format('woff2'),
        url('./Segeo/SegoeUI.woff') format('woff'),
        url('./Segeo/SegoeUI.ttf') format('truetype'),
        url('./Segeo/SegoeUI.svg#SegoeUI') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('./Segeo/SegoeUI-Italic.eot');
    src: url('./Segeo/SegoeUI-Italic.eot?#iefix') format('embedded-opentype'),
        url('./Segeo/SegoeUI-Italic.woff2') format('woff2'),
        url('./Segeo/SegoeUI-Italic.woff') format('woff'),
        url('./Segeo/SegoeUI-Italic.ttf') format('truetype'),
        url('./Segeo/SegoeUI-Italic.svg#SegoeUI-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}