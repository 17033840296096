.p-autocomplete-input-token {
    padding: 0px;
  }
  .p-autocomplete-multiple-container {
    width: 100%;
  }

  p-autocomplete ul.p-autocomplete-multiple-container {
    display: block !important;
    width: 100% !important;
    padding: 0.35rem 0.75rem !important;
    font-size: 1.1rem !important;
    font-weight: 500 !important;
    line-height: 1.5 !important;
    color: #181C32 !important;
    background-color: #ffffff !important;
    background-clip: padding-box !important;
    border: 1px solid #E4E6EF !important;
    border-radius: 0rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
    margin: 0px !important;

    li{
      padding: 0.25rem 0.5rem !important;
      margin-left: 0.25rem !important;

      input{
        color: #181C32 !important;
        font-size: 1.1rem !important;
        font-weight: 500 !important;
        border: none !important;
        padding: 0.81rem 0rem !important;
      }
    }
  }
