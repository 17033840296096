.svd-toolbar-dropdown {
    line-height: 30px;
}

.svd-toolbar-dropdown .svd_custom_select .select2 .selection .select2-selection .select2-selection__arrow {
    margin-right: 0px;
}

.sv-footer__complete-btn, .sv_complete_btn{
    display: none;
}

.svd_survey_designer .svd-designer-container--left-side {
    width: 25%;
    max-width: 25%;
}

.svd_survey_designer .svd-designer-container--right-side {
    width: 25%;
    max-width: 25%;
}

.sv-body__page {
    margin: 10px 0 0 0;
}

.sv-body__footer {
    margin: 0;
    padding-top: 0.87em;
}

.sv-container-modern__title {
    color: #3699ff !important;
    margin-right: 5px;
    margin-left: 5px;
    padding-top: 0.9375em;
}

.sv-question__title--answer {
    background-color: rgba(0, 156, 232, 0.2) !important;
}

img[alt="Drop questions here"] {
    content: url(../../assets/common/images/app-logo-on-dark.svg);
}